<div class="main-container">
  <div class="header">
    <div class="header-title">
      <b>{{ assessmentSettings?.title }}</b>
      <mat-icon class="documentation-icon" (click)="openHelp()" *ngIf="learnerReportMode">help</mat-icon>
    </div>

    <div class="buttons-parent">
      <button mat-stroked-button color="primary" (click)="showSessionLogs()" *ngIf="dialogMode">
        <mat-icon>remove_red_eye</mat-icon>
        <span>SHOW USER SESSION LOGS</span>
      </button>
      <button mat-stroked-button color="primary" (click)="openAssessmentDashboard()" *ngIf="learnerReportMode && canEdit && hasClickedAssessmentButton">
        <mat-icon>remove_red_eye</mat-icon>
        <span>VIEW ALL REPORTS</span>
      </button>
      <div class="button-tag-container" *ngIf="learnerReportMode && canEdit && hasClickedAssessmentButton">
        <button mat-stroked-button color="primary" (click)="openAssessmentEditor()">
          <span class="edit-assessment-icon"></span>
          <span>EDIT ASSESSMENT</span>
        </button>
        <div class="button-tag">New</div>
      </div>
    </div>
  </div>

  <div class="content-container">
    <div class="content-panel left-panel">
      <div class="info-container">
        <div class="avatar">{{ initials }}</div>
        <div class="info-column">
          <div class="username-parent">
            <span class="username">{{ userName }}</span>
            <span *ngIf="!learnerReportMode">Score: </span>
            <span *ngIf="learnerReportMode">Indicative Score: </span>
            <span class="score">{{ score }}/{{ scoreMax }}</span>
          </div>
          <div class="info">
            <div>
              <b>Assessment date: </b>
              <span>{{ assessmentResult.createdAt | date:'medium' }}</span>
            </div>
            <div *ngIf="assessmentResult.session">
              <b>Session date: </b>
              <span>{{ assessmentResult.session.date | date:'medium' }}</span>
            </div>
            <div *ngIf="assessmentResult.session">
              <b>Duration: </b>
              <span>{{ assessmentResult.session.duration | durationConverter }}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div echarts [options]="chartOption" class="radar-chart"></div>
      </div>

      <div class="evaluation-section">
        <div class="evaluation-title-container">
          <span class="evaluation-title">Summary</span>
        </div>
        <div class="evaluation-content">{{ assessmentResult.report.evaluationSummary }}</div>
      </div>
    </div>

    <div class="content-panel right-panel">
      <ng-container *ngFor="let criteria of assessmentResult.report.criterias">
        <div class="evaluation-section">
          <div class="evaluation-title-container">
            <span class="evaluation-title"> {{ criteria.index + 1 }}. {{ criteria.title }}</span>
            <span class="evaluation-ellipis">&nbsp;-&nbsp;</span>
            <span class="score">{{ criteria.grade }}/5 pts</span>
          </div>
          <div class="evaluation-content">{{ criteria.insight }}</div>
        </div>
      </ng-container>
      
      <div class="evaluation-section" *ngIf="assessmentResult.report.recommendations">
        <div class="evaluation-title-container">
          <span class="evaluation-title">Recommendations</span>
        </div>
        <div class="evaluation-content">{{ assessmentResult.report.recommendations }}</div>
      </div>
    </div>
  </div>

  <div class="buttons-parent">
    <button mat-button color="primary" (click)="closeDialog()" *ngIf="dialogMode">
      <span>CLOSE</span>
    </button>
    <button mat-raised-button (click)="restart()" *ngIf="learnerReportMode">
      <mat-icon>replay</mat-icon>
      <span>RESTART</span>
    </button>
    <button mat-raised-button color="primary" (click)="openAssessmentEditor()" *ngIf="learnerReportMode && canEdit && !hasClickedAssessmentButton">
      <img width="12px" class="edit-assessment-icon" src='/assets/svg/assessment.svg'>
      <span>EDIT ASSESSMENT</span>
    </button>

    <div class="button-container" *ngIf="!learnerReportMode || !canEdit || hasClickedAssessmentButton">
      <button mat-raised-button color="primary" [class.hidden]="downloadInProgress" (click)="downloadReport()">
        <mat-icon>download</mat-icon>
        <span>DOWNLOAD REPORT</span>
      </button>
      <div class="stacked-child" *ngIf="downloadInProgress">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </div>
  </div>
</div>
