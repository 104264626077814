import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AssessmentResult } from '@shared/models';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AssessmentService {
  constructor(
    private _http: HttpClient,
  ) {}

  public getAssessments(courseId: string, limit: number = 10): Observable<AssessmentResult[]> {
    return this._http.get<AssessmentResult[]>(environment.coursesApiUrl + `/api/assessments?courseId=${courseId}&limit=${limit}`);
  };

  public getAssessmentBySessionId(sessionId: string): Observable<AssessmentResult | undefined> {
    return this._http.get<any>(environment.coursesApiUrl + `/api/assessments/sessions/${sessionId}`);
  };

  public generate(sessionId: string, courseId: string, creatorId: string, trigger: string): Observable<any> {
    const body = { sessionId, courseId, creatorId, trigger };
    return this._http.post<any>(environment.coursesApiUrl + '/api/assessments', body);
  };

  public getAssessmentsExportDataByCourseId(courseId: string, limit: number = 10){
    const url = environment.coursesApiUrl + `/api/assessments/export/courses/${courseId}`;
    window.open(url);
  }
  
  public getAssessmentExportDataBySessionId(courseId: string, sessionId: string, format: string = 'pdf'): Observable<boolean> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = environment.coursesApiUrl + `/api/assessments/export/courses/${courseId}/sessions/${sessionId}?format=${format}&timeZone=${timeZone}`;

    return this._http.get(url, { responseType: 'blob' }).pipe(
      tap(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `assessments-export_course_${courseId}-session_${sessionId}__${new Date().toISOString().split('T')[0]}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.removeChild(link);
      }),
      map(() => true),
      catchError(error => {
        return of(false);
      })
    );
  }
}
