import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EditThirdPartyIntegrationsComponent } from '@app/main/settings/third-party-integrations/edit-third-party-integrations/edit-third-party-integrations.component';
import { KalturaAccountFormComponent } from '@app/main/settings/third-party-integrations/kaltura-account-form/kaltura-account-form.component';
import { IBMAccountFormComponent } from '@app/main/settings/third-party-integrations/ibm-account-form/ibm-account-form.component';
import { GetThirdPartyIntegrationsComponent } from '@app/main/settings/third-party-integrations/get-third-party-integrations/get-third-party-integrations.component';
import { LTICredentialsComponent } from '@app/main/settings/third-party-integrations/lti-credentials/lti-credentials.component';
import { SsoConfigComponent } from '@app/main/settings/third-party-integrations/sso-config/sso-config.component';
import { LLMSettingsFormComponent } from '@app/main/settings/third-party-integrations/llm-settings-form/llm-settings-form.component';

@NgModule({
  declarations: [
    EditThirdPartyIntegrationsComponent,
    IBMAccountFormComponent,
    KalturaAccountFormComponent,
    GetThirdPartyIntegrationsComponent,
    LTICredentialsComponent,
    SsoConfigComponent,
    LLMSettingsFormComponent
  ],
  imports: [SharedModule],
  exports: [
    EditThirdPartyIntegrationsComponent,
    GetThirdPartyIntegrationsComponent
  ],
  entryComponents: [IBMAccountFormComponent, KalturaAccountFormComponent, LTICredentialsComponent]
})
export class SettingsModule {}
