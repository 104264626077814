import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentResult, Course } from '@shared/models';
import { AssessmentService } from '../../shared/services/assessment.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CourseAnalyticsTab } from '@shared/services/analytics.service';

enum Error {
  'default' = 'default',
  'rateLimit' = 'rateLimit'
}

@Component({
  selector: 'learner-report',
  templateUrl: './learner-report.component.html',
  styleUrls: ['./learner-report.component.scss']
})
export class LearnerReportComponent implements OnInit {
  public course?: Course;
  public canEdit: boolean = false;
  public hasClickedAssessmentButton: boolean = false;
  public assessmentResult?: AssessmentResult;
  public error?: Error;

  constructor(
    private _courseService: CoursesAPIService,
    private _assessmentService: AssessmentService,
    private _authenticationService: AuthenticationService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe(async params => {
      const wondaUser = await this._authenticationService.getWondaUser();
      this.hasClickedAssessmentButton = wondaUser ? wondaUser.feature_usage?.hasClickedAssessmentButton : true;

      const courseId = params.get('courseId');
      const sessionId = params.get('sessionId');
      this.loadData(courseId, sessionId);
    });
  }

  private loadData(courseId: string, sessionId: string) {
    this._courseService.getCourseViewLazy(courseId).subscribe(course => {
      this.course = course;
      this.canEdit = course.learnspace.isParticipantOrHubAdmin;
    });
    
    this._assessmentService.getAssessmentBySessionId(sessionId).subscribe(existingAssessment => {
      this.assessmentResult = existingAssessment;
    }, (e) => {
      if (e.status !== 404) {
        this.error = Error.default;
        return;
      }
      const creatorId = this._authenticationService.wondaUser ? this._authenticationService.wondaUser.id : undefined
      this._assessmentService.generate(sessionId, courseId, creatorId, "learner-view").subscribe(assessmentResult => {
        this.assessmentResult = assessmentResult;
      }, (e) => {
        this.error = e.status === 429 ? Error.rateLimit : Error.default;
      });
    });
  }

  restart() {
    history.back();
  }

  async openAssessmentDashboard(editor: boolean = false) {
    await this.updateFeatureUsageIfNeeded();

    const url = `analytics/${this.course.learnspace.hub.urlTitle}/${this.course.learnspace_id}/courses/${this.course.id}`;
    this._router.navigate([url], {
      queryParams: {
        'activeTab': CourseAnalyticsTab.Assessment,
      },
      fragment: editor ? 'editor' : undefined
    });
  }

  private async updateFeatureUsageIfNeeded() {
    if (this.hasClickedAssessmentButton) return;
    
    const feature_usage = { hasClickedAssessmentButton: true };
    await this._authenticationService.updateMyUser({ feature_usage }).toPromise();
  };
}
