import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';

@Component({
  selector: 'llm-settings-form',
  templateUrl: './llm-settings-form.component.html',
  styleUrls: ['./llm-settings-form.component.scss'],
})
export class LLMSettingsFormComponent implements OnChanges {
  @Input() hubId: string;

  isCustomLLMEnabled: boolean = false;
  form: FormGroup;
  selectedType: string;
  errorMessage?: string;
  editableOpenAIKey: boolean = false;

  private fields: { [key: string]: string[] } = {
    'openAI': ['openAIKey']
  };

  constructor(
    private _courseApiService: CoursesAPIService,
    private _internalAnalyticsService: InternalAnalyticsService
  ) {
    this.form = new FormGroup({
      type: new FormControl(''),
      openAIKey: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(/^sk-[\S]{20,}$/)
        ]
      })
    });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.hubId) {
      this._courseApiService.getHubIntegrations(this.hubId).subscribe(creds => {
        this.resetForm(creds?.llm);
      });

      this._courseApiService.getHub(this.hubId).subscribe(hub => {
        this.isCustomLLMEnabled = hub.properties?.isCustomLLMEnabled;
      });
    }
  }

  onLLMTypeChange(): void {
    this.updateSelectedType();
  }

  onDeleteOpenAIKey(): void {
    this.editableOpenAIKey = true;
    this.form.controls['openAIKey'].reset('');
  }

  onSave(): void {
    const llmSettings = this.form.value || { type: 'default' };
    if (llmSettings?.openAIKey?.includes('***')) {
      return;
    }

    this._courseApiService
      .updateHubLLMSettings(this.hubId, llmSettings)
      .subscribe(updatedSettings => {
        this.resetForm(updatedSettings);
      }, error => {
        this.errorMessage = error.error;
      });
  }

  private resetForm(llmSettings?: any): void {
    this.form.reset(llmSettings || { type: 'default' });
    this.editableOpenAIKey = !llmSettings?.openAIKey;
    this.updateSelectedType();
  }

  private updateSelectedType(): void {
    this.errorMessage = undefined;
    this.selectedType = this.form.get('type').value;

    // Only keep fields associated with the selected llm type.
    for (const controlName in this.form.controls) {
      const control = this.form.controls[controlName];
      if (this.fields[this.selectedType]?.includes(controlName)) {
        control.enable();
      } else if (controlName !== 'type') {
        control.disable();
      }
    }
  }

  openHelp(): void {
    window.open('https://help.spaces.wondavr.com/en/articles/10412376-use-your-own-llm');
  }

  openOpenAIHelp(): void {
    window.open('https://platform.openai.com/api-keys');
  }

  contactSales(): void {
    this._internalAnalyticsService.showNewMessage();
  }
}
