import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import {
  Course,
  Section,
  Scene,
  Asset,
  AssetType,
  Annotation,
  AnnotationType,
  Learnspace,
  Invitation,
  PaginatedUsers,
  ListQueryOptions,
  User,
  Hub,
  Plan,
  PlanResponse, CourseRelease
} from '@shared/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { SupervisorService } from '@shared/services/supervisor.service';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Integrations } from '@shared/models/integrations.model';

@Injectable()
export class CoursesAPIService {
  selectionHandler: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _supervisor: SupervisorService,
    private _internalAnalyticsService: InternalAnalyticsService,
    private _authService: AuthenticationService
  ) { }

  /**
   *  Users API
   */

  public getUserByEmail(email: string): Observable<any> {
    return this._http.get(environment.coursesApiUrl + '/api/users/email/' + email);
  }

  public updateMyUser(changes: Partial<User>): Observable<User> {
    return this._http.patch<User>(environment.coursesApiUrl + '/api/users/me', changes);
  }

  public deleteMyUser(): Observable<any> {
    return this._http.delete(environment.coursesApiUrl + '/api/users/me', { observe: 'response', responseType: 'text' })
      .pipe(
        map(resp => resp.body)
      );
  }



  /**
   * Hubs API
   */

  // TODO should allow this only once front and back !
  public createHub(hub: Hub): Observable<Hub> {
    return this._http.post<Hub>(`${environment.coursesApiUrl}/api/hubs`, hub).pipe(
      tap(() => this._authService.reloadWondaUser())
    );
  }
  public updateHub(id: string, changes: Partial<Hub>): Observable<Hub> {
    return this._http.patch<Hub>(`${environment.coursesApiUrl}/api/hubs/${id}`, changes).pipe(
      tap(() => this._authService.reloadWondaUser())
    );
  }
  // id or urlTitle
  public getHub(id: string, scope: string = null, showAll: boolean = false): Observable<Hub> {
    let params = new HttpParams();
    if (scope) {
      params = params.set('scope', scope);
    }
    if (showAll) {
      params = params.set('all', showAll.toString());
    }
    return this._http.get<Hub>(`${environment.coursesApiUrl}/api/hubs/${id}`, { params: params });
  }

  public searchHubBySsoDomain(domain: string): Observable<Hub[]> {
    let params = new HttpParams().set('ssoDomain', domain);
    return this._http.get<Hub[]>(`${environment.coursesApiUrl}/api/hubs/byDomain`, { params: params })
  }
  public getHubUsers(urlTitle: string, options: ListQueryOptions): Observable<PaginatedUsers> {
    let params = new HttpParams();
    if (options) {
      params = getHttpQueryParams(params, options);
    }
    return this._http.get<PaginatedUsers>(`${environment.coursesApiUrl}/api/hubs/${urlTitle}/users`, { params: params });
  }

  public getHubMinInfo(id: string): Observable<Hub> {
    return this._http.get<Hub>(`${environment.coursesApiUrl}/api/hubs/${id}/minInfo`);
  }

  public getHubPlanInfo(id: string): Observable<Plan> {
    return this._http.get<PlanResponse>(`${environment.coursesApiUrl}/api/hubs/${id}/plan`).pipe(
      map(plan => {
        if (plan.maxCourse === "Infinity") {
          plan.maxCourse = Infinity;
        }
        if (plan.maxSpace === "Infinity") {
          plan.maxSpace = Infinity;
        }
        return plan as Plan;
      })
    );
  }
  public getHubIntegrations(id: string): Observable<Integrations> {
    return this._http.get(`${environment.coursesApiUrl}/api/hubs/${id}/integrations`);
  }

  public getHubIntegrationsInfo(id: string): Observable<Integrations> {
    return this._http.get(`${environment.coursesApiUrl}/api/hubs/${id}/integrationsInfo`);
  }

  public patchHubIntegrations(id: string, change: any): Observable<any> {
    return this._http.patch(`${environment.coursesApiUrl}/api/hubs/${id}/integrations`, change);
  }

  public updateHubLLMSettings(id: string, settings: any): Observable<any> {
    return this._http.patch(`${environment.coursesApiUrl}/api/hubs/${id}/llmSettings`, settings);
  }

  public updateHubSSOConfig(id: string, config: any): Observable<Hub> {
    return this._http.put<Hub>(`${environment.coursesApiUrl}/api/hubs/${id}/sso`, config);
  }
  public associateSelfToDefaultHub(): Observable<Hub> {
    return this._http.post<Hub>(`${environment.coursesApiUrl}/api/hubs/default/associateuser/self`, {})
      .pipe(tap(() => this._authService.reloadWondaUser()));
  }
  public associateUserToHub(hub_id: string, user_id: string, role: string, subscribeTo?: string[], unsubscribeTo?: string[]): Observable<any> {
    const body: any = { userId: user_id, role: role };
    if (subscribeTo) {
      body.subscribeTo = subscribeTo;
    }
    if (unsubscribeTo) {
      body.unsubscribeTo = unsubscribeTo;
    }
    return this._http.post(`${environment.coursesApiUrl}/api/hubs/${hub_id}/associateuser`, body, { observe: 'response', responseType: 'text' })
      .pipe(tap(() => this._authService.reloadWondaUser()));
  }
  public dissociateUserFromHub(hub_id: string, user_id: string): Observable<any> {
    return this._http.post(`${environment.coursesApiUrl}/api/hubs/${hub_id}/dissociateuser`, { userId: user_id })
      .pipe(tap(() => this._authService.reloadWondaUser()));
  }
  public checkUnicity(urlTitle: string): Observable<{ unicity: boolean, message: string }> {
    return this._http.get<{ unicity: boolean, message: string }>(`${environment.coursesApiUrl}/api/hubs/checkUnicity/${urlTitle}`);
  }

  public getAssocitatedLearnspacesByUserIdByHubId(userId: string, hubId: string): Observable<Learnspace[]> {
    return this._http.get<Learnspace[]>(`${environment.coursesApiUrl}/api/hubs/${hubId}/learnspaces/forUser/${userId}`);
  }
  // LTI Support
  public getLTICredentials(id: string): Observable<any> {
    return this._http.get(`${environment.coursesApiUrl}/api/hubs/${id}/lticredentials`);
  }
  public enableLTI(hub_id: string): Observable<Hub> {
    return this._http.get<Hub>(environment.coursesApiUrl + `/api/hubs/${hub_id}/enable_lti`);
  }
  public disableLTI(hub_id: string): Observable<Hub> {
    return this._http.get<Hub>(environment.coursesApiUrl + `/api/hubs/${hub_id}/disable_lti`);
  }

  public updateLTIIntegration(hub_id: string, lti_platforms?: any): Observable<Hub> {
    return this._http.post<Hub>(environment.coursesApiUrl + `/api/hubs/${hub_id}/updateltiintegration`, { lti_platforms: lti_platforms });
  }

  public getHubStats(id: string): Observable<any> {
    return this._http.get<Hub>(`${environment.coursesApiUrl}/api/hubs/${id}/stats`);
  }
  public getHubMostActiveCreators(id: string, options: { dateFrom: Date, dateTo: Date }): Observable<User[]> {
    return this._http.post<User[]>(`${environment.coursesApiUrl}/api/hubs/${id}/mostactivecreators`, { date_from: options.dateFrom, date_to: options.dateTo });
  }
  public getHubCourseCreationsPerMonth(id: string, options: { dateFrom: Date, dateTo: Date }): Observable<User[]> {
    return this._http.post<User[]>(`${environment.coursesApiUrl}/api/hubs/${id}/coursecreations`, { date_from: options.dateFrom, date_to: options.dateTo });
  }
  /**
   * Learnspaces API
   */

  public shareLearnspaceByEmail(id: string, email: string): Observable<any> {
    return this._http.post(`${environment.coursesApiUrl}/api/learnspaces/${id}/share`, { user_email: email, role: 'editor' });
  }

  public getLearnspaces(): Observable<Learnspace[]> {
    return this._http.get<Learnspace[]>(environment.coursesApiUrl + '/api/learnspaces');
  }

  public getLearnspacesByHub(hubId: string): Observable<Learnspace[]>;
  public getLearnspacesByHub(hubId: string, options: ListQueryOptions): Observable<{ total?: number, learnspaces: Learnspace[] }>;
  public getLearnspacesByHub(hubId: string, options?: ListQueryOptions): Observable<{ total?: number, learnspaces: Learnspace[] } | Learnspace[]> {
    let params = new HttpParams();
    if (options) {
      params = getHttpQueryParams(params, options);
    }
    return this._http.get<{ total?: number, learnspaces: Learnspace[] | Learnspace[] }>(`${environment.coursesApiUrl}/api/hubs/${hubId}/learnspaces`, { params });
  }

  public countLearnspacesInHub(hubId: string): Observable<any> {
    return this._http.get(`${environment.coursesApiUrl}/api/hubs/${hubId}/learnspaces/count`);
  }

  public getLearnspacesJoinedByHub(hubId: string, options: ListQueryOptions): Observable<{ total?: number, learnspaces: Learnspace[] }> {
    let params = new HttpParams();
    if (options) {
      params = getHttpQueryParams(params, options);
    }
    return this._http.get<{ total?: number, learnspaces: Learnspace[] }>(`${environment.coursesApiUrl}/api/hubs/${hubId}/learnspaces/joined`, { params });
  }

  public getLearnspaceById(id: string): Observable<Learnspace> {
    return this._http.get<Learnspace>(environment.coursesApiUrl + '/api/learnspaces/' + id);
  }

  public createLearnspace(ls: Learnspace): Observable<Learnspace> {
    return this._http.post<Learnspace>(environment.coursesApiUrl + '/api/learnspaces', ls)
      .pipe(tap(newLs => this._internalAnalyticsService.trackLSCreation(newLs)));
  }
  public updateLearnspace(id: string, ls: Partial<Learnspace>): Observable<Learnspace> {
    return this._http.put<Learnspace>(environment.coursesApiUrl + '/api/learnspaces/' + id, ls);
  }

  public deleteLearnspace(id: string): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/learnspaces/' + id, { observe: 'response', responseType: 'text' })
      .pipe(
        map(resp => resp.body)
      );
  }

  public unsubscribeLearnspace(lsId: string, userId: string): Observable<any> {
    return this._http.post(`${environment.coursesApiUrl}/api/learnspaces/${lsId}/unsubscribe`,
      { user_id: userId }
    );
  }

  public getLearnspaceAssetsByType(type: string, id: string): Observable<Asset[]> {
    return this._http.get<Asset[]>(`${environment.coursesApiUrl}/api/learnspaces/${id}/medias/type/${type}`);
  }

  public getParticipants(id: string): Observable<User[]> {
    return this._http.get<User[]>(`${environment.coursesApiUrl}/api/learnspaces/${id}/participants`);
  }
  public getLearnspaceCourseList(id: string): Observable<Course[]> {
    return this._http.get<Course[]>(`${environment.coursesApiUrl}/api/learnspaces/${id}/courses`);
  }

  /**
   * COURSES API
   */
  public getCourses(): Observable<{ total: number | undefined, courses: Course[] }> {
    return this._http.get<{ total: number | undefined, courses: Course[] }>(environment.coursesApiUrl + '/api/courses');
  }
  // TODO: Make the getCourses parametrable, when a more advanced use of getCourse will be required
  public getFeaturedCourses(): Observable<{ total: number | undefined, courses: Course[] }> {
    return this._http.get<{ total: number | undefined, courses: Course[] }>(environment.coursesApiUrl + '/api/courses/?search=is_featured:true,public_status:open&sort=-updatedAt');
  }
  public getCourseById(id: string): Observable<Course> {
    return this._http.get<Course>(
      environment.coursesApiUrl + '/api/courses/' + id
    );
  }
  public getCourseInfo(id: string): Observable<Course> {
    return this._http.get<Course>(
      environment.coursesApiUrl + '/api/courses/' + id + '/info'
    );
  }
  public getCourseView(id: string): Observable<Course> {
    return this._http.get<Course>(
      environment.coursesApiUrl + '/api/courses/' + id + '/view'
    );
  }
  public getCourseViewLazy(id: string): Observable<Course> {
    return this._http.get<Course>(
      environment.coursesApiUrl + '/api/courses/' + id + '/viewlazy'
    );
  }

  public getCourseSections(id: string): Observable<Section[]> {
    return this._http.get<Section[]>(
      environment.coursesApiUrl + '/api/courses/' + id + '/sections'
    );
  }
  public getCourseScenes(id: string): Observable<Scene[]> {
    return this._http.get<Scene[]>(
      environment.coursesApiUrl + '/api/courses/' + id + '/scenes'
    );
  }

  public countCourseScenes(id: string): Observable<any> {
    return this._http.get<{ count: number }>(
      environment.coursesApiUrl + '/api/courses/' + id + '/scenes/count'
    );
  }
  public getCourseAnnotationsByType(id: string, type: AnnotationType): Observable<Annotation[]> {
    return this._http.get<Annotation[]>(
      environment.coursesApiUrl + '/api/courses/' + id + '/annotations/type/' + type
    );
  }
  public getCourseDynamicAnnotations(id: string): Observable<{ annotations: Annotation[] }> {
    return this._http.get<{ annotations: Annotation[] }>(
      environment.coursesApiUrl + '/api/courses/' + id + '/annotations/dynamic'
    );
  }
  public getCourseLinks(id: string): Observable<{ domain: string, presenterCode: string, learnerCode: string, presenterLink: string, learnerLink: string }> {
    return this._http.get<{ domain: string, presenterCode: string, learnerCode: string, presenterLink: string, learnerLink: string }>(
      environment.coursesApiUrl + '/api/courses/' + id + '/links'
    );
  }
  public createCourse(learnspaceId: string, templateId: string, templateTitle: string): Observable<Course> {
    return this._http.post<Course>(environment.coursesApiUrl + '/api/courses', { learnspace_id: learnspaceId, template_id: templateId })
      .pipe(tap(newCourse => this._internalAnalyticsService.trackCourseCreation(newCourse, templateId, templateTitle)));
  }

  public updateCourse(courseId: string, changes: Partial<Course>, reload: boolean = true): Observable<Course> {
    return this._http.put<Course>(environment.coursesApiUrl + '/api/courses/' + courseId, changes)
      .pipe(
        map(resp => {
          if (reload) {
            this._supervisor.askForCourseReload({ action: 'update', type: 'course', content: resp });
          }
          return resp;
        })
      );
  }

  public duplicateCourse(id: string): Observable<Course> {
    return this._http.post<Course>(`${environment.coursesApiUrl}/api/courses/${id}`, null);
  }

  public deleteCourse(id: string): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/courses/' + id, { observe: 'response', responseType: 'text' })
      .pipe(
        map(resp => resp.body)
      );
  }

  public countCourseMedias(id: string): Observable<{ count: number }> {
    return this._http.get<{ count: number }>(
      environment.coursesApiUrl + '/api/courses/' + id + '/medias/count'
    );
  }

  /**
   *  SECTION API
   */
  public getSection(sectionId: string): Observable<Section> {
    return this._http.get<Section>(
      environment.coursesApiUrl + '/api/sections/' + sectionId,
    );
  }

  public getSectionScenes(sectionId: string): Observable<Scene[]> {
    return this._http.get<Scene[]>(
      environment.coursesApiUrl + '/api/sections/' + sectionId + '/scenes',
    );
  }
  public createSection(section: Section): Observable<Section> {
    return this._http.post<Section>(
      environment.coursesApiUrl + '/api/sections',
      section
    )
  }

  public duplicateSection(section_id: string): Observable<Section> {
    return this._http.post<Section>(
      `${environment.coursesApiUrl}/api/sections/${section_id}`,
      null
    )
  }

  public updateSection(sectionId: string, changes: Partial<Section>): Observable<Section> {
    return this._http.patch<Section>(
      environment.coursesApiUrl + '/api/sections/' + sectionId,
      changes
    )
  }

  public deleteSection(id: String): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/sections/' + id, { observe: 'response', responseType: 'text' })
      .pipe(
        map(resp => resp.body),
        map(delId => {
          return delId;
        })
      );
  }

  /**
   *  SCENES API
   */
  public createScene(scene: Scene): Observable<Scene> {
    return this._http.post<Scene>(
      environment.coursesApiUrl + '/api/scenes',
      scene
    );
  }
  public getSceneAnnotations(scene_id: string): Observable<Annotation[]> {
    return this._http.get<Annotation[]>(
      environment.coursesApiUrl + '/api/scenes/' + scene_id + '/annotations'
    );
  }
  public duplicateScene(scene_id: string): Observable<Scene> {
    return this._http.post<Scene>(
      `${environment.coursesApiUrl}/api/scenes/${scene_id}`,
      null
    )
  }

  public updateScene(sceneId: string, changes: Partial<Scene>): Observable<Scene> {
    return this._http.patch<Scene>(
      environment.coursesApiUrl + '/api/scenes/' + sceneId,
      changes
    );
  }

  public deleteScene(scene: Scene): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/scenes/' + scene.id, { observe: 'response', responseType: 'text' })
      .pipe(
        map(resp => resp.body),
        map(respId => {
          return respId;
        })
      );
  }
  /**
   * ANNOTATIONS API
   */
  public getAnnotation(annotation_id: string): Observable<Annotation> {
    return this._http.get<Annotation>(
      environment.coursesApiUrl + '/api/annotations/' + annotation_id
    )
  }
  public createAnnotation(annotation: Annotation): Observable<Annotation> {
    return this._http.post<Annotation>(
      environment.coursesApiUrl + '/api/annotations/',
      annotation
    ).pipe(tap(() => this._supervisor.askForAnnotationListReload()));
  }
  public updateAnnotation(annotation: Annotation): Observable<Annotation> {
    return this._http.put<Annotation>(
      environment.coursesApiUrl + '/api/annotations/' + annotation.id,
      annotation);
  }

  public restoreAnnotation(id: string): Observable<Annotation> {
    return this._http.put<Annotation>(
      environment.coursesApiUrl + '/api/annotations/' + id + '/restore', {}
    ).pipe(tap(() => this._supervisor.askForAnnotationListReload()));
  }

  public deleteAnnotation(id: string): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/annotations/' + id, { observe: 'response', responseType: 'text' })
      .pipe(
        tap(() => this._supervisor.askForAnnotationListReload()),
        map(resp => resp.body)
      );
  }

  /**
   * ASSETS API
   */
  public getAssets(): Observable<Asset[]> {
    return this._http.get<Asset[]>(environment.coursesApiUrl + '/api/medias');
  }

  public getAssetsByType(type: AssetType): Observable<Asset[]> {
    return this._http.get<Asset[]>(
      environment.coursesApiUrl + '/api/medias/type/' + type
    );
  }

  public getCourseAssetsByType(type: AssetType, course_id: string): Observable<Asset[]> {
    return this._http.get<Asset[]>(
      environment.coursesApiUrl + '/api/courses/' + course_id + '/medias/type/' + type
    );
  }

  public createMedia(asset: Asset, course_id: string = null): Observable<Asset> {
    const params: any = {
      asset: asset
    };
    if (course_id) {
      params.course_id = course_id;
    }
    return this._http.post<Asset>(
      environment.coursesApiUrl + '/api/medias',
      params
    );
  }

  public getAsset(assetId: string): Observable<Asset> {
    return this._http.get<Asset>(environment.coursesApiUrl + '/api/medias/' + assetId);
  }

  public updateMedia(asset: Asset): Observable<Asset> {
    return this._http.put<Asset>(
      environment.coursesApiUrl + '/api/medias/' + asset.id,
      asset
    );
  }

  public partialUpdateMedia(assetId: string, changes: Partial<Asset>): Observable<Asset> {
    return this._http.patch<Asset>(
      environment.coursesApiUrl + '/api/medias/' + assetId,
      changes
    );
  }

  public addMediaToCourse(asset: Asset, course_id: string): Observable<any> {
    return this._http.post<Asset>(
      environment.coursesApiUrl + '/api/courses/' + course_id + '/medias/',
      { media_id: asset.id }
    );
  }
  /**
   * Room api API
   */
  public getDefaultRoom(): Observable<Asset> {
    return this._http.get<Asset>(
      environment.coursesApiUrl + '/api/medias/defaultroom'
    );
  }

  public deleteMedia(id: string): Observable<string> {
    return this._http.delete(environment.coursesApiUrl + '/api/medias/' + id, { observe: 'response', responseType: 'text' })
      .pipe(map(resp => resp.body));
  }

  public checkMediaUse(asset_id: string): Observable<{ isUsed: string }> {
    return this._http.get<any>(`${environment.coursesApiUrl}/api/medias/checkUse?mediaId=${asset_id}`);
  }
  public checkRoomUse(asset_id: string): Observable<{ message: string, courseList?: Course[] }> {
    return this._http.get<any>(`${environment.coursesApiUrl}/api/medias/checkRoomUse?mediaId=${asset_id}`).pipe(map(resp => {
      if (!resp) {
        return null;
      }
      if (resp.coursesUsing && resp.coursesUsing.length > 0) {
        return { message: 'in the following presentation(s)', courseList: resp.coursesUsing };
      }
      return null;
    }));
  }
  /**
   * Invitations API
   */

  public createInvitation(invit: Invitation): Observable<Invitation> {
    return this._http.post<Invitation>(`${environment.coursesApiUrl}/api/invitations`, invit)
      .pipe(
        tap(newInvit => this._internalAnalyticsService.trackInvitation(newInvit)),
        catchError(err => {
          if (err.status == 429) {
            throw new Error("Too many invitation requests, please retry later");
          } else {
            throw err
          }
        })
      );
  }

  public getInvitation(id: string): Observable<Invitation> {
    return this._http.get<Invitation>(`${environment.coursesApiUrl}/api/invitations/${id}`);
  }

  public activateInvitation(id: string): Observable<Invitation> {
    return this._http.patch<Invitation>(`${environment.coursesApiUrl}/api/invitations/${id}/activate`, {})
      .pipe(tap(() => this._authService.reloadWondaUser()));
  }

  /**
   * Course release api
   */

  public getLatestCourseRelease(courseId: string): Observable<CourseRelease> {
    return this._http.get<CourseRelease>(
      `${environment.coursesApiUrl}/api/courses/${courseId}/releases/latest`
    );
  }

  public releaseCourse(courseId: string, version: string): Observable<CourseRelease> {
    return this._http.post<CourseRelease>(
      `${environment.coursesApiUrl}/api/courses/${courseId}/releases/${version}`,
      {}
    );
  }

  // Translation API
  public downloadTranslationFile(courseId: string): Window {
    return window.open(`${environment.coursesApiUrl}/api/courses/${courseId}/translation`)
  }
  public uploadTranslationFile(courseId: string, data: FormData): Observable<string> {
    return this._http.post<string>(
      `${environment.coursesApiUrl}/api/courses/${courseId}/translation`,
      data
    );
  }
}

function getHttpQueryParams(httpParams: HttpParams, listQueryOptions: ListQueryOptions): HttpParams {
  if (!listQueryOptions) {
    return httpParams;
  }
  let oHttpParams = httpParams;
  if (!isNaN(listQueryOptions.offset)) {
    oHttpParams = oHttpParams.set('offset', listQueryOptions.offset + '');
  }
  if (!isNaN(listQueryOptions.limit)) {
    oHttpParams = oHttpParams.set('limit', listQueryOptions.limit + '');
  }
  if (listQueryOptions.total) {
    oHttpParams = oHttpParams.set('total', listQueryOptions.total + '');
  }
  if (listQueryOptions.sort) {
    oHttpParams = oHttpParams.set('sort', listQueryOptions.sort);
  }
  if (listQueryOptions.search) {
    oHttpParams = oHttpParams.set('search', listQueryOptions.search);
  }
  return oHttpParams;
}
