<div class="form-container">
  <h4>LLM <mat-icon class="documentation-icon" (click)="openHelp()">help</mat-icon></h4>

  <div class="intro">
    Set the Learning Language Model (LLM) you want to use in your hub. Use your organization’s API key to better comply with your organization’s internal security & confidentiality policies.
  </div>

  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>LLM</mat-label>
      <mat-select id="type" formControlName="type" (selectionChange)="onLLMTypeChange()">
        <mat-option value="default">Default</mat-option>
        <mat-option value="openAI">OpenAI</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!isCustomLLMEnabled && selectedType !== 'default'">
      <p><span class="link" (click)="contactSales()">Contact us</span> to use your own API key.</p>
    </ng-container>

    <div class="form-fields" *ngIf="isCustomLLMEnabled">
      <ng-container *ngIf="selectedType === 'openAI'">
        <p *ngIf="selectedType==='openAI'">
          You can find your Open AI Secret API key on the <span class="link" (click)="openOpenAIHelp()">API key page</span>.
        </p>
        <div class="horizontal-form-field">
          <mat-form-field>
            <mat-label>API Key</mat-label>
            <input matInput id="openAIKey" formControlName="openAIKey" type="text" [readonly]="!editableOpenAIKey"/>
            <mat-error *ngIf="form.get('openAIKey').hasError('required') && form.get('openAIKey').dirty">
              This field is required
            </mat-error>
            <mat-error *ngIf="form.get('openAIKey').hasError('pattern') && form.get('openAIKey').dirty">
              Invalid API Key Format
            </mat-error>
          </mat-form-field>
          <button mat-icon-button class="delete-button" matTooltip="Delete" (click)="onDeleteOpenAIKey()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <div *ngIf="errorMessage" class="error-msg">
      {{ errorMessage }}
    </div>
  </form>

  <div class="confirm-button">
    <button mat-raised-button color="primary" aria-label="CONFIRM" (click)="onSave()" [disabled]="!isCustomLLMEnabled || form.invalid || !form.dirty">
      CONFIRM
    </button>
  </div>
</div>
